<template>
  <div class="df-flex-xl df-flex-col purchase-summary">
    <v-skeleton-loader
      v-if="loadingPlans"
      height="166px"
      type="image"
      width="auto"
    />
    <div v-else class="df-flex-m df-flex-col summary__infos">
      <h1>Resumo da compra</h1>
      <div class="df-flex-sm df-flex-col infos__card">
        <div class="d-flex justify-space-between">
          <span>{{ paymentFrequency }}</span>
          <span>{{ planPrice }}</span>
        </div>
        <p>{{ planText }}</p>
      </div>
      <div class="d-flex justify-space-between infos__total-value">
        <span>Valor total</span>
        <p>{{ totalValue }}</p>
      </div>
    </div>
    <div class="df-flex-m df-flex-col">
      <df-button :loading="loadingPayment" @click="makePayment">
        Efetuar pagamento
      </df-button>
      <p class="purchase__cancel">Cancele a qualquer momento.</p>
      <p class="purchase__terms">
        A assinatura renova automaticamente, a menos que a renovação automática
        seja desativada por pelo menos 24 horas antes do período de cobrança.
        Consulte nossos
        <a href="" target="_blank">Termos</a> para mais informações sobre o
        cancelamento e reembolso.
      </p>
    </div>
    <payment-approved-modal
      v-if="isApprovedModalOpened"
      :plan-priority="planPriority"
      @close="closeApprovedModal"
    />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import PaymentApprovedModal from '@/modules/payment/components/modal/PaymentApprovedModal.vue'
import paymentService from '@/modules/payment/services/paymentService'
import { planInfos, planTexts } from '@/modules/payment/data/planPt'
import { EventBus } from '@/utils/eventBus'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PurchaseSummary',

  components: {
    DfButton,
    PaymentApprovedModal,
  },

  data() {
    return {
      isApprovedModalOpened: false,
      loadingPayment: false,
      loadingPlans: false,
      paymentParams: {},
      paymentFrequency: this.$route.query.frequency,
      planPriority: this.$route.query.plan_priority,
      plans: [],
      selectedPlan: {},
    }
  },

  async created() {
    this.loadingPlans = true
    try {
      const [allDigifarmPlans, allVindiPlans] = await Promise.allSettled([
        this.$api.plans.getAll(),
        paymentService.getVindiPlans(),
      ])
      const currentPlanName = planInfos[this.planPriority].name.toLowerCase()
      if (allDigifarmPlans.status === 'fulfilled') {
        this.plans = allDigifarmPlans.value.data
      }
      if (allVindiPlans.status === 'fulfilled') {
        const filteredPlans = allVindiPlans.value.data.data.filter(
          (payment) =>
            payment.status === 'active' &&
            payment.name.toLowerCase().includes(currentPlanName)
        )
        this.selectedPlan = filteredPlans.find(
          (plan) => plan.interval_name == this.paymentFrequency
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingPlans = false
      EventBus.$on('dataSent', this.updateData)
      EventBus.$on('paymentForm', this.setFormRef)
    }
  },

  beforeDestroy() {
    EventBus.$off('dataSent', this.updateData)
    EventBus.$off('paymentForm', this.setFormRef)
  },

  watch: {
    drawer() {
      const component = document.querySelector('.purchase-summary')
      if (component && window.matchMedia('(min-width: 1536px)').matches) {
        if (this.drawer) {
          component.style.left = 'calc(50% + 440px)'
        } else {
          component.style.left = 'calc(50% + 340px)'
        }
      } else if (
        component &&
        window.matchMedia('(min-width: 1263px)').matches
      ) {
        component.style.left = 'auto'
      } else {
        component.style.left = '0px'
      }
    },
  },

  computed: {
    ...mapGetters('layout', ['drawer']),
    ...mapGetters('user', ['currentUser']),
    planPrice() {
      return this.selectedPlan?.plan_items[0].pricing_schema.short_format
    },
    planText() {
      return planTexts[this.paymentFrequency]
    },
    totalValue() {
      return this.selectedPlan?.plan_items[0].pricing_schema.short_format
    },
  },

  methods: {
    ...mapActions('permissions', ['fetchPermissions']),
    ...mapActions('user', ['fetchUserData']),
    closeApprovedModal() {
      this.isApprovedModalOpened = false
    },
    async createBill(customerId) {
      const params = {
        customer_id: customerId,
        payment_method_code: this.paymentParams.paymentData.payment_method_code,
        installments: this.selectedPlan.installments,
        due_at: null,
        bill_items: [
          {
            product_id: this.selectedPlan.plan_items[0].product.id,
            amount: Number(
              this.selectedPlan.plan_items[0].pricing_schema.price
            ),
          },
        ],
      }
      try {
        return await paymentService.createBill(params)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async createSubscription(customerId) {
      const params = {
        start_at: null,
        plan_id: this.selectedPlan.id,
        customer_id: customerId,
        payment_method_code: this.paymentParams.paymentData.payment_method_code,
        installments: this.selectedPlan.installments,
      }
      try {
        return await paymentService.createSubscription(params)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async createVindiCustomer() {
      try {
        const { data } = await paymentService.createCustomer(
          this.paymentParams.customerData
        )
        const customerId = data.data.id
        return customerId
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async createPaymentProfile(customerId) {
      const params = {
        customer_id: customerId,
        holder_name: this.paymentParams.paymentData.holder_name,
        registry_code: this.paymentParams.paymentData.registry_code,
        card_number: this.paymentParams.paymentData.card_number,
        card_expiration: this.paymentParams.paymentData.card_expiration,
        card_cvv: this.paymentParams.paymentData.card_cvv,
        payment_method_code: this.paymentParams.paymentData.payment_method_code,
      }
      try {
        await paymentService.createPaymentProfile(params)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async makePayment() {
      EventBus.$emit('requestData')
      if (!this.paymentForm.validate()) return
      this.loadingPayment = true
      let status = ''
      try {
        const customerId = await this.createVindiCustomer()
        await this.updateVindiId(customerId)
        await this.createPaymentProfile(customerId)
        if (this.selectedPlan.interval_name !== 'Anual') {
          const { data } = await this.createSubscription(customerId)
          status = data.data.bill.status
        } else {
          const { data } = await this.createBill(customerId)
          status = data.data.status
        }
        if (status === 'pending') {
          this.$router.push({
            path: '/catalog',
          })
          this.$root.$emit(
            'notify',
            'warning',
            'Pagamento em andamento. Aguarde um tempo e atualize o site para que o novo plano seja aplicado.'
          )
        } else {
          await this.updateUserPlan()
          await this.fetchUserData(this.$root.$i18n)
          this.fetchPermissions()
          this.isApprovedModalOpened = true
        }
      } catch (error) {
        console.error(error)
        this.$root.$emit(
          'notify',
          'error',
          'Pagamento recusado. Não conseguimos aprovar seu pagamento, confira os dados do seu cartão e tente novamente'
        )
      } finally {
        this.loadingPayment = false
      }
    },
    setFormRef(ref) {
      this.paymentForm = ref
    },
    updateData(data) {
      this.paymentParams = data
    },
    async updateUserPlan() {
      const interval = {
        anual: 'annual',
        mensal: 'monthly',
      }
      const params = {
        account_id: Number(this.currentUser.account.id),
        interval: interval[this.selectedPlan.interval_name.toLowerCase()],
        plan_id: String(
          this.plans.find((plan) => plan.priority_level == this.planPriority).id
        ),
      }
      try {
        await paymentService.updateUserPlan(params)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async updateVindiId(customerId) {
      const params = {
        account_id: Number(this.currentUser.account.id),
        vindi_id: customerId,
      }
      try {
        await paymentService.updateVindiId(params)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.purchase-summary {
  max-width: 408px;
  position: fixed;
  top: 156px;
  left: calc(50% + 340px);
  padding: 24px;
  border-radius: 8px;
  background-color: #f8f9f8;
  transition: left 0.2s ease;

  @media (max-width: 1536px) {
    right: 24px;
    left: auto;
  }
  @include d(t) {
    position: relative;
    max-width: 472px;
    top: 0px;
    left: 0px;
  }
  @include d(m) {
    position: relative;
    max-width: 472px;
    top: 0px;
    left: 0px;
  }

  .summary__infos {
    h1 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .infos__card {
      padding: 24px;
      border-radius: 8px;
      border: 1px solid #e6e9e6;
      background: #fff;
      font-family: 'Rubik';
      font-style: normal;
      font-size: 16px;
      line-height: 20px;

      span {
        color: #1a2b46;
        font-weight: 500;
      }
      p {
        color: #5b6459;
        font-weight: 400;
      }
    }
    .infos__total-value {
      font-family: 'Rubik';
      font-style: normal;
      font-size: 16px;
      line-height: 20px;

      span {
        color: #1a2b46;
        font-weight: 500;
      }
      p {
        color: #5b6459;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .purchase__cancel {
    color: #5b6459;
    text-align: center;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .purchase__terms {
    color: #5b6459;
    text-align: center;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    a {
      color: #4a76bc;
      text-decoration-line: underline;
    }
  }
}
</style>
